var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-screen flex-1 mt-1 bg-gray-100 px-4 py-3 items-center"
  }, [_c('div', {
    staticClass: "bg-white w-full rounded-lg shadow pb-20"
  }, [_c('div', {
    staticClass: "h-20 py-3 flex items-center border-gray-200 p-6 mt-5"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_c('button', {
    staticClass: "rounded-full flex justify-center items-center w-8 h-8 mr-3",
    class: _vm.$colors.primary,
    on: {
      "click": function click($event) {
        return _vm.RouterBack();
      }
    }
  }, [_c('i', {
    staticClass: "material-icons"
  }, [_vm._v("chevron_left")])]), _c('div', {
    staticClass: "text-xl font-bold text-green-700"
  }, [_vm._v(_vm._s(_vm.$t('shipping_details')))])])]), _c('div', {
    attrs: {
      "id": "detail"
    }
  }, [_c('div', {
    staticClass: "grid lg:grid-cols-2 gap-6"
  }, [_c('div', {
    staticClass: "flex justify-between shadow w-full h-auto p-4 my-1 mx-2 rounded-lg border-gray-100"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(0), _c('div', {
    staticClass: "ml-5 w-full space-y-2"
  }, [_c('div', {
    staticClass: "text-base mt-2 mb-2 font-semibold text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('detail')))]), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('shipping_address')) + ": "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.address) + " ")])]), _vm.formData.zone && _vm.formData.country != 'MA' ? _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('zone_shipping')) + ": "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.zone.name) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "text-sm text-gray-600"
  }, [_vm._v(" " + _vm._s(_vm.$t('shipping_date')) + ": "), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.date) + " ")])])])])]), _c('div', {
    staticClass: "flex justify-between shadow w-full h-auto p-4 my-1 mx-2 rounded-lg border-gray-100"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(1), _c('div', {
    staticClass: "ml-5 w-full"
  }, [_c('div', {
    staticClass: "text-base mt-2 mb-2 font-semibold text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('customer_information')) + " ")]), _c('div', {
    staticClass: "text-sm font-light text-gray-700"
  }, [_c('i', {
    staticClass: "material-icons text-gray-400"
  }, [_vm._v("person")]), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.order.customer.fullName) + " ")])]), _c('div', {
    staticClass: "text-sm font-light text-gray-700"
  }, [_c('i', {
    staticClass: "material-icons text-gray-400"
  }, [_vm._v("room")]), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.order.customer.address) + " ")])]), _c('div', {
    staticClass: "text-sm font-light text-gray-700"
  }, [_c('i', {
    staticClass: "material-icons text-gray-400"
  }, [_vm._v("phone")]), _c('span', {
    staticClass: "justify-center text-xs text-center p-1 pl-3 pr-2 w-auto font-semibold rounded-full text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.formData.order.customer.phone) + " ")])])])])])]), _c('div', {
    staticClass: "flex pr-5 pl-5 mt-6"
  }, [_c('div', {
    staticClass: "flex justify-between w-full h-auto p-4 rounded-lg border-gray-100"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_vm._m(2), _c('div', {
    staticClass: "ml-1 w-full p-2"
  }, [_c('div', {
    staticClass: "text-base font-semibold text-gray-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('products_order')) + " ")]), _c('div', {
    staticClass: "w-full mt-4"
  }, [_c('table', {
    staticClass: "min-w-full leading-normal"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(_vm._s(_vm.$t('image')))]), _c('th', {
    staticClass: "px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(_vm._s(_vm.$t('product')))]), _c('th', {
    staticClass: "px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(_vm._s(_vm.$t('quantity')))]), _c('th', {
    staticClass: "px-5 py-3 border-b-2 border-gray-200 text-left text-xs font-semibold text-gray-600 capitalize tracking-wider"
  }, [_vm._v(_vm._s(_vm.$t('price')))])])]), _vm._l(_vm.formData.order.details, function (item, index) {
    return _c('tbody', {
      key: item._id
    }, [_c('tr', {
      class: index % 2 == 0 ? 'bg-gray-100' : ''
    }, [_c('td', {
      staticClass: "px-5 py-2 border-b border-gray-200 bg-white text-sm"
    }, [_c('span', {
      staticClass: "flex text-gray-700 capitalize whitespace-no-wrap"
    }, [_c('span', {
      staticClass: "mt-1"
    }, [_c('img', {
      staticClass: "h-16 w-16 rounded-md mx-auto",
      attrs: {
        "src": item.product.picture || 'https://storeino.b-cdn.net/dev/templates/images/no-image.jpeg'
      }
    })])])]), _c('td', {
      staticClass: "px-5 py-2 border-b border-gray-200 bg-white text-sm"
    }, [_c('span', {
      staticClass: "flex text-gray-700 capitalize whitespace-no-wrap"
    }, [_c('span', {
      staticClass: "mt-1"
    }, [_vm._v(_vm._s(item.product.name))])])]), _c('td', {
      staticClass: "px-5 py-2 border-b border-gray-200 bg-white text-xs"
    }, [_c('p', {
      staticClass: "text-gray-700 ml-6 whitespace-no-wrap"
    }, [_c('span', {
      staticClass: "font-semibold"
    }, [_vm._v(_vm._s(item.quantity) + " ")])])]), _c('td', {
      staticClass: "px-5 py-2 border-b border-gray-200 bg-white text-xs"
    }, [_c('span', {
      staticClass: "justify-center text-xs text-center pl-2 pr-2 py-1 rounded-full text-gray-700"
    }, [_c('span', {
      staticClass: "text-gray-800 font-semibold text-xs ml-1"
    }, [_vm._v(" " + _vm._s(item.unitPrice) + " ")]), _c('sup', {
      staticClass: "uppercase"
    }, [_vm._v(_vm._s(_vm.currentCurrency))])])])])]);
  })], 2)])])])])]), _c('div', {
    staticClass: "flex pr-5 pl-5 mt-6"
  }, [_c('div', {
    staticClass: "flex justify-between w-full h-auto p-4 rounded-lg border-gray-100"
  }, [_c('div', {
    staticClass: "flex w-full"
  }, [_c('div', {
    staticClass: "ml-1 w-full p-2"
  }, [_c('div-table', {
    attrs: {
      "config": _vm.configList,
      "data": _vm.shippingsHistories,
      "idWharhouse": _vm.idWharhouse,
      "currentUser": _vm.currentUser,
      "loading": _vm.loading,
      "paginate": _vm.paginate
    },
    on: {
      "paginatation": _vm.paginatation
    }
  })], 1)])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-100"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("list_alt")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-100"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("person")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "rounded-full h-12 w-12 bg-gray-100"
  }, [_c('i', {
    staticClass: "material-icons p-3 text-green-400"
  }, [_vm._v("local_mall")])]);

}]

export { render, staticRenderFns }